import React from 'react'
import { Box, Image, Text } from 'theme-ui'

export default function NoDataBox({ variant, pageObjectKey, businessLogo }) {
  const renderText = objectKey => {
    switch (objectKey) {
      case 'blog':
        return 'There Are No Articles At This Time'
      case 'team':
        return 'There Are No Team Members Listed At This Time'
      case 'press':
        return 'There Are No Press Articles At This Time'

      case 'policies':
        return 'There Are No Policies Listed At This time'

      default:
        return ''
    }
  }

  return (
    <Box variant={`${variant}.noDataContainer`} className="noDataContainer">
      <Image variant={`${variant}.noDataLogo`} className="noDataLogo" src={businessLogo} alt="logo" />
      <Text as="h3" variant={`${variant}.NoDataText1`} className="NoDataText1">
        {renderText(pageObjectKey)}
      </Text>
      <Text as="h4" variant={`${variant}.NoDataText2`} className="NoDataText2">
        Please Come Back Again Later.
      </Text>
    </Box>
  )
}
