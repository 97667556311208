import React from 'react'
import { Box, Label, Input } from 'theme-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

export default function Search({ variant, setArticleFilterSettings, articleFilterSettings, value }) {
  return (
    <Box variant={`${variant}.searchContainer`} className="searchContainer">
      <Label variant={`${variant}.filterLabel`}>Search</Label>
      <Box variant={`${variant}.searchInputContainer`}>
        <Input
          // defaultValue={value}
          placeholder="Search"
          type="text"
          value={value}
          onChange={e => {
            setArticleFilterSettings({
              ...articleFilterSettings,
              search: e.target.value
            })
          }}
        />
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() =>
            setArticleFilterSettings({
              ...articleFilterSettings,
              search: ''
            })
          }
        />
      </Box>
    </Box>
  )
}
